<script>
	import { fly } from 'svelte/transition';
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';
	import { getContext } from 'svelte';
	import Donate from '$lib/components/donate.svelte';
	//	import { flip } from 'svelte/animate';

	import { cubicIn, cubicOut } from 'svelte/easing';
	export let data;

	//	console.log(data);
	let featuredTrivia = data.latest;
	const now = new Date();
	let triviaIndex = now.getDay();
	let featuredQuestion = data.latest.questions[triviaIndex];
	let answerMode = false;
	let answer = '';
	let flipping;
	const answeredQuestions = getContext('answeredQuestions');

	function handleSubmit() {
		if (answer.length >= 1) {
			answerMode = true;
			//	console.log(answeredQuestions);
			answeredQuestions.update((current) => {
				current[featuredQuestion.id] = featuredQuestion;
				current[featuredQuestion.id].answered = true;
				current[featuredQuestion.id].userAnswer = answer;
				return current;
			});
		}
	}
	let homeReady = false;
	onMount(() => {
		triviaIndex = now.getDay();
		featuredQuestion = data.latest.questions[triviaIndex];
		homeReady = true;
	});
	let inAnimation = { y: 10, duration: 200, easing: cubicOut, delay: 200 };
	let outAnimation = { y: -10, duration: 200, easing: cubicOut };
</script>

<svelte:head>
	<title>Pedro's Trivia | Weekly Fun Trivia</title>
	<meta property="og:title" content="Pedro's Trivia | Weekly Fun Trivia" />
	<meta
		name="description"
		content="Pedro's Trivia is weekly fun trivia passion project brought to you by Pedro the director of the South Burlington Foodh Shelf."
	/>
	<meta property="og:image" content={`https://trivia.stomprocket.io/og.png`} />
	<meta property="og:image:type" content="image/png" />
</svelte:head>

{#if homeReady}
	<main in:fly={inAnimation} out:fly={outAnimation}>
		<div class="home__question-of-the-day">
			<h1 class:answerMode data-flip-key="page-title" class="page-title">
				pedro's trivia question of the day:
			</h1>

			<div>
				<div class="question__row" class:answerMode>
					<div class="question__container">
						<p class="question" class:answerMode data-flip-key="question">
							{featuredQuestion.question}
						</p>
					</div>
				</div>
				{#if answerMode}
					<p transition:fly={{ easing: cubicIn, y: -10, duration: 300, delay: 200 }} class="answer">
						{featuredQuestion.answer}
					</p>
				{/if}
			</div>

			{#if !answerMode}
				<form
					class="answerbox"
					on:submit|preventDefault={handleSubmit}
					transition:fade={{ easing: cubicIn, duration: 50 }}
				>
					<input
						class="answerbox--input"
						type="text"
						placeholder="your answer here"
						accept="text"
						bind:value={answer}
						autocomplete="off"
					/>
					<button class="btn" type="submit">enter</button>
				</form>
			{:else}
				<div class="userAnswerBox">
					<p transition:fade={{ easing: cubicIn, duration: 300, delay: 800 }}>you said:</p>
					<p
						class="userAnswer"
						transition:fly={{ easing: cubicIn, y: -10, duration: 300, delay: 1600 }}
					>
						{answer}
					</p>
				</div>
			{/if}

			<div class="info" class:answerMode>
				<div>
					{#if answerMode}
						<a
							href="/play/{featuredTrivia.id}"
							class="play-rest mb"
							transition:fly={{ easing: cubicIn, y: -10, duration: 300, delay: 2400 }}
							>continue with the rest of this week’s trivia!</a
						>
					{/if}
					<p class="text-block theme" data-flip-key="theme" class:answerMode>
						<strong>theme:</strong>
						{featuredTrivia.theme}
					</p>
					{#if !answerMode}
						<a
							href="/play/{featuredTrivia.id}"
							class="play-rest"
							transition:fly={{ easing: cubicIn, y: 10, duration: 50, delay: 0 }}
							>play the rest of this week’s trivia</a
						>
					{/if}
				</div>
				<div transition:fly={{ y: 20, duration: 700, easing: cubicOut, delay: 1000 }}>
					<Donate showDonate={false} />
				</div>
			</div>
		</div>
		<section class="rest-of-page" data-flip-key="rest-of-page">
			<h1>More Trivia</h1>
			<div class="trivia-grid">
				{#each data.triviaLinks as trivia}
					<a class="trivia-link" href={'/play/' + trivia.id}>
						<h2>{trivia.id}</h2>
						<p class="text-block">{trivia.theme}</p>
					</a>
				{/each}
			</div>
			<div class="evenmore">
				<a href="/library" class="btn prominate">even more trivia</a>
			</div>
		</section>
	</main>
{/if}

<style>
	.answerbox--input {
		border-bottom: 3px solid black;
	}
	.answerbox--input:hover {
		border-bottom: 4px solid black;
	}
	.answerbox--input:focus {
		border-bottom: 4px solid black;
	}
	main {
		max-width: 1200px;
	}
	.theme {
		margin-top: 3em;
	}
	.theme.answerMode {
		margin-top: 0em;
	}
	.mb {
		margin-bottom: 4em;
	}
	div.trivia-grid {
		margin-top: 2em;
	}
	.rest-of-page {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr;

		column-gap: 1.5em;
		margin-top: 2em;
	}

	.question__row {
		width: 100%;
	}
	.question__row.answerMode {
		display: flex;
	}
	.home__question-of-the-day {
		display: block;

		margin-bottom: 2em;
	}

	.page-title {
		font-size: 1.25em;
		margin-bottom: 1em;
		margin-top: 2em;
		font-variation-settings: 'wght' 500;
	}

	.answer {
		font-size: 3rem;
		letter-spacing: 2px;
		font-variation-settings: 'wght' 600;
		margin-top: 0.1em;
	}
	.question {
		font-size: 3rem;
		letter-spacing: 2px;
		font-variation-settings: 'wght' 600;
		transition: 200ms cubic-bezier();
		line-height: 1.5;
	}
	.question.answerMode {
		color: gray;
		font-size: 2rem;
		letter-spacing: 0px;
		transition: 200ms cubic-bezier();
		margin-top: 1em;
	}

	.userAnswer {
		font-size: 2em;
		font-variation-settings: 'wght' 600;
		margin-top: 0.25em;
	}
	.answerbox {
		margin-top: 10vh;
	}
	.userAnswerBox {
		margin-top: 10vh;
		display: flex;
		flex-direction: column;
	}
	.info {
		margin-top: 5em;
		grid-row: 4;

		transition: 100ms ease-out;
		display: grid;
		grid-template-columns: auto 18em;
		grid-gap: 2em;
	}
	.info.answerMode {
		margin-top: 3em;
	}

	.play-rest {
		display: block;
		margin-top: 2em;
	}
	@media only screen and (max-device-width: 1300px) {
		.question {
			letter-spacing: 0px;
			line-height: 1.25;
		}
	}
	@media only screen and (max-device-width: 991px) and (min-device-width: 768px) {
		.trivia-grid {
			grid-template-columns: 1fr 1fr;
		}
	}
	@media only screen and (max-device-width: 767px) {
		.home__question-of-the-day {
			grid-gap: 1em;
		}
		.rest-of-page {
			margin-top: 4em;
		}

		.trivia-grid {
			grid-template-columns: 1fr 1fr;
		}
		.info {
			display: grid;
			grid-template-columns: 1fr;
		}
		.question {
			font-size: 2rem;
			letter-spacing: 0px;
			line-height: 1.25;
		}

		.answerbox--input {
			font-size: 1rem;
			line-height: 1;
		}
	}
	@media only screen and (max-device-width: 600px) {
		.page-title {
			margin-top: 2em;
			margin-bottom: 1.5em;
			font-variation-settings: 'wght' 400;
			font-size: 1rem;
		}
		.question {
			font-size: 1.5rem;
		}
		.question.answerMode {
			font-size: 1.5rem;
			margin-bottom: 1em;
		}
		.trivia-grid {
			grid-template-columns: 1fr;
		}
		.answer {
			font-size: 2rem;
		}
		.userAnswer {
			font-size: 1.5rem;
		}

		.answerbox {
			display: flex;
			flex-direction: column;
		}
		.answerbox--input {
			width: 100%;
			margin-bottom: 1em;
			padding: 1em 0em;
		}
		.answerbox > .btn {
			margin-left: auto;
		}
		.info {
			margin-top: 2em;
		}
	}
</style>
